<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import getEnv from '@/utils/env'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

const displayV6Navigation = getEnv('VUE_APP_DISPLAY_V6_NAVIGATION')

export default {
  components: {
    HorizontalNavMenuItems,
  },
  computed: {
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    navMenuItems() {
      if (displayV6Navigation === '1') {
        return this.navMenuItemsV6
      }
      return this.navMenuItemsV5
    },
    navMenuItemsV5() {
      const items = [
        {
          title: 'Home',
          route: 'home',
          icon: 'HomeIcon',
        },
      ]

      if (this.isAdmin) {
        items.push({
          header: 'Admin',
          icon: 'SlidersIcon',
          children: [
            {
              title: 'Definition Settings',
              route: 'admin-definition-settings',
              icon: 'CpuIcon',
            },
            {
              title: 'Definitions',
              route: 'admin-definitions',
              icon: 'ListIcon',
            },
            {
              title: 'Other Settings',
              route: 'admin-other-settings',
              icon: 'SettingsIcon',
            },
          ],
        })

        items.push({
          title: 'Batches',
          route: 'batches',
          icon: 'ListIcon',
        })
      }

      return items
    },
    navMenuItemsV6() {
      const items = [
        {
          header: 'Home',
          icon: 'HomeIcon',
          children: [
            {
              title: 'Transactions',
              route: 'home',
              icon: 'BarChart2Icon',
            },
            {
              title: 'Create Profile',
              route: 'create-profile',
              icon: 'PlusIcon',
            },
            {
              title: 'Profile Management',
              route: 'profiles',
              icon: 'ListIcon',
            },
          ],
        },
        {
          header: 'Analyzer',
          icon: 'ActivityIcon',
          children: [
            {
              title: 'Training',
              route: 'training',
              icon: 'BookOpenIcon',
            },
          ],
        },
      ]

      if (this.isAdmin) {
        items.push({
          header: 'Admin',
          icon: 'SlidersIcon',
          children: [
            {
              title: 'Batches',
              route: 'batches',
              icon: 'ServerIcon',
            },
            {
              title: 'Projects',
              route: 'projects',
              icon: 'FileTextIcon',
            },
            {
              title: 'Templates',
              route: 'templates',
              icon: 'ClipboardIcon',
            },
            {
              title: 'Application Settings',
              route: 'admin-application-settings',
              icon: 'ToolIcon',
            },
            {
              title: 'Definition Settings',
              route: 'admin-definition-settings',
              icon: 'CpuIcon',
            },
            {
              title: 'Other Settings',
              route: 'admin-other-settings',
              icon: 'SettingsIcon',
            },
          ],
        })
      }
      if (this.$route.name === 'batch') {
        items[1].children.push(
          {
            title: 'Automated Table Model',
            route: 'automated-table-model',
            icon: 'ZapIcon',
          },
        )
      }
      return items
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
