<template>
  <div class="flex-grow-1">
    <div
      v-if="toggleNavbar"
      class="navbar-container d-flex content align-items-center"
      :style="{
        background: backgroundColor
      }"
    >
      <div
        v-if="layoutType === 'horizontal'"
        class="navbar-header d-xl-block d-none"
      >
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <h2
                class="brand-text mb-0 pl-0"
                :style="{
                  color: appTitleColor
                }"
              >
                {{ appTitle }}
              </h2>
            </b-link>
          </li>
        </ul>
      </div>
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>
      <div class="align-items-center flex-grow-1 d-none d-lg-flex">
        <b-link
          v-if="layoutType === 'horizontal'"
          class="navbar-brand d-xl-block d-none"
          to="/"
        >
          <logo />
        </b-link>
        <div
          v-if="isNavMenuHidden"
          class="custom-horizontal-nav header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light menu-border d-none d-xl-block"
          :style="{
            background: backgroundColor
          }"
        >
          <horizontal-nav-menu />
        </div>
      </div>
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item
          v-if="$route.name === 'batch'"
          @click="onToggoleNavbar"
        >
          <feather-icon
            size="21"
            icon="ChevronsUpIcon"
          />
        </b-nav-item>
        <b-nav-item :to="{ name: 'documentation' }">
          <feather-icon
            size="21"
            icon="HelpCircleIcon"
          />
        </b-nav-item>
        <dark-toggler />
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0 text-capitalize">
                {{ userName }}
              </p>
              <span
                v-if="isAdmin"
                class="user-status"
              >admin</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              :text="userInitials"
            />
          </template>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Logout</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logoutAll"
          >
            <feather-icon
              size="16"
              icon="PowerIcon"
              class="mr-50"
            />
            <span>Logout All</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
    <div
      v-else
      class="app-auto-suggest"
    >
      <div
        class="expand-button badge badge-primary"
        variant="primary"
        @click="onToggoleNavbar"
      >
        <feather-icon
          class="text-white"
          size="24"
          icon="ChevronsDownIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BNavItem,
} from 'bootstrap-vue'
import axios from 'axios'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HorizontalNavMenu from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'
import bus from '@/bus'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Logo from './Logo.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    Logo,
    HorizontalNavMenu,
    DarkToggler,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BNavItem,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      toggleNavbar: true,
    }
  },
  computed: {
    appTitle() {
      return this.$store.getters['theme/settings'].appTitle
    },
    userName() {
      return this.$store.getters['auth/userName']
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    userInitials() {
      if (!this.userName) {
        return ''
      }
      return this.userName.split(' ').slice(0, 3).map(name => name.charAt(0)).join('')
    },
    backgroundColor() {
      return this.$store.getters['theme/settings'][this.skin].headerColor
    },
    appTitleColor() {
      return this.$store.getters['theme/settings'][this.skin].appTitleColor
    },
  },
  setup() {
    const {
      isNavMenuHidden,
      layoutType,
      skin,
    } = useAppCustomizer()

    return {
      isNavMenuHidden,
      layoutType,
      skin,
    }
  },
  methods: {
    createNewTable() {
      bus.$emit('createNewTable')
    },
    logout() {
      axios
        .post('/access_control/logout/')
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

      this.$store.dispatch('auth/clearAuthData')
      this.$router.push({ name: 'login' })
      localStorage.clear()
    },
    logoutAll() {
      axios
        .post('/access_control/logoutall/')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully logged out from all devices',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          this.$store.dispatch('auth/clearAuthData')
          this.$router.push({ name: 'login' })
          localStorage.clear()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onToggoleNavbar() {
      this.toggleNavbar = !this.toggleNavbar
      bus.$emit('toggle-navbar', this.toggleNavbar)
    },
  },
}
</script>

<style lang="scss" scoped>
.expand-button {
  position: absolute;
  top: 10px;
  right: 8px;
  cursor: pointer;
}
</style>
